import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DocumentsRevision } from '@models';
import { RequirementDocsT16 } from "@consts";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  documentsRevision$: Observable<DocumentsRevision[]>;

  documentsRevisionSource = new BehaviorSubject(null);
  documentsRevisionTarget$ = this.documentsRevisionSource.asObservable();

  documentsRevisionOne = new BehaviorSubject(null);
  documentsRevisionOne$ = this.documentsRevisionOne.asObservable();

  documentsAprobacion = new BehaviorSubject(null);
  documentsAprobacionTarget$ = this.documentsAprobacion.asObservable();

  totalDocuments = new BehaviorSubject(null);
  totalDocuments$ = this.totalDocuments.asObservable();

  documentsSolicSubject = new BehaviorSubject(null);
  public readonly documentsSolicitanteTarget$ = this.documentsSolicSubject.asObservable();

  documentsRequired = new BehaviorSubject(null);
  public readonly documentsRequired$ = this.documentsRequired.asObservable();

  constructor(private http: HttpClient) {
    this.documentsRevision$ = of([]);
  }

  setDocsAdded(document: any) {
    this.documentsSolicSubject.next(document);
  }

  setDocsRequire(requiredDoc: any) {
    this.documentsRequired.next(requiredDoc);
  }

  public get docsAdded() {
    return this.documentsSolicSubject.value || [];
  }

  public get numDocsRequired() {
    const docsRequired = [];
    for (const docsEl of RequirementDocsT16) {
      if (docsEl.required) {
        docsRequired.push(docsEl.id);
      }
    }
    return docsRequired;
  }

  public get docsRequiredOk(): boolean {
    const docsRequired = this.numDocsRequired;
    let docsAddedRequired = 0;
    for (const docReqEl of this.docsAdded) {
      if (docsRequired.indexOf(docReqEl.idReqdocument) != -1) {
        docsAddedRequired++;
      }
    }
    return docsAddedRequired == docsRequired.length;
  }

}
