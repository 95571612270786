import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthModel, SSOAuthResponse } from '@models';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { Rol, RoutesApp } from "@enums";
import { MsalService } from "@azure/msal-angular";
import { UserService } from "./user.service";
import { PopupService } from "./popup.service";
import { environment } from "../../../environments/environment";
import { RequestService } from './request.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public currentUserSubject: BehaviorSubject<SSOAuthResponse>;
  public currentUser: Observable<SSOAuthResponse>;
  private users!: AuthModel[];
  counterChangesRefresh = 1;
  response = false;

  subSolicitante = new BehaviorSubject(null);
  subSolicitante$ = this.subSolicitante.asObservable();
  setNaturalPerson = new BehaviorSubject<boolean>(false);
  getNaturalPerson$ = this.setNaturalPerson.asObservable();

  private noInterceptorHttpClient: HttpClient;

  constructor(private http: HttpClient, private msalService: MsalService, handler: HttpBackend, private router: Router,
              private userService: UserService, private alert: PopupService, private requestService: RequestService) {
    this.users = [];

    this.currentUserSubject = new BehaviorSubject<SSOAuthResponse>(JSON.parse(String(localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();

    this.noInterceptorHttpClient = new HttpClient(handler);
  }

  public get currentUserValue(): SSOAuthResponse {
    return this.currentUserSubject.value;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (err) {
      return null;
    }
  }

  isCurrentUserAndNotTokenExpired(): boolean {
    try {
      const currentUser = this.currentUserValue;
      if (localStorage.getItem('dateIn') === undefined || localStorage.getItem('dateIn') === null) {
        localStorage.setItem('dateIn', new Date().toString());
      }
      const before = new Date(localStorage.getItem('dateIn'));
      let dataRefresh = 0;
      if (!isNaN(currentUser.refresh_expires_in)) {
        dataRefresh = currentUser.refresh_expires_in;
      }
      let refresh = (0.9 * dataRefresh) / 60;
      const timeReal = new Date();
      const diff = Math.round(((timeReal.getTime() - before.getTime()) / 60000) / this.counterChangesRefresh);
      let valueExpire = 0;
      if (localStorage.getItem('currentUser')) {
        valueExpire = JSON.parse(localStorage.getItem('currentUser'))?.expires_in ? JSON.parse(localStorage.getItem('currentUser')).expires_in : 0;
      }
      const compareRefresh = 0.9 * (valueExpire / 60);
      if (refresh <= diff) {
        this.cleanAll();
        return false;
      } else if (compareRefresh <= diff) {
        return this.response;
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  public LoginB2CAzure(): any {
    this.msalService.loginPopup().subscribe(
      res => {
        this.userService.getCodeVentanillaByIdUser(res.account.idTokenClaims.oid).subscribe({
          next: (ventanillaCode) => {
            if (ventanillaCode.data == -1) {
              const vtUrl = environment.VUDTS_URL;
              this.alert.errorAlert(`Hemos detectado que no se ha completado el registro, porfavor dirigase al portal de <a href='${vtUrl}' target="_blank" rel="noopener">Agilínea</a>`, 4500);
              setTimeout(() => this.cleanAllAndLogout(), 4500);
              return;
            }

            this.userService.getRoleByIdUser(res.account.idTokenClaims.oid).subscribe(role => {
              if (role.data != null) {
                const currentUser = {
                  access_token: res.idToken,
                  sub: res.account.idTokenClaims.sub,
                  refresh_token: res.account.idTokenClaims.auth_time + "",
                  refresh_expires_in: res.account.idTokenClaims.exp,
                  notBeforePolicy: res.account.idTokenClaims.iat ? res.account.idTokenClaims.iat : 0,
                  scope: res.scopes[0] ? res.scopes[0] : '',
                  id_token: res.idToken ? res.idToken : '',
                  token_type: res.tokenType,
                  session_state: res.state ? res.state : '',
                  expires_in: res.account.idTokenClaims.exp,
                  rol: this.getExactlyRole(role.data[0].value.toLowerCase()),
                  sid: '',
                  email_verified: role.data[0].email ? role.data[0].email : false,
                  name: res.account.idTokenClaims.name,
                  preferred_username: res.account.username,
                  given_name: res.account.idTokenClaims.given_name + "",
                  family_name: res.account.idTokenClaims.family_name + "",
                  email: role.data[0].email,
                  userId: res.account.idTokenClaims.oid,
                };
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
                this.currentUserSubject.next(currentUser);
                this.alert.infoAlert('Bienvenid@ a Secretaría de Salud Bogotá.', 4000);
                if (currentUser.rol == Rol.CitizenOrApplicant) {
                  // Cambiar estado de la solicitud a desistimiento por el sistema
                  this.requestService.updateRequestWithdrawal().subscribe(resp => {
                    console.log(resp);
                  })
                  this.router.navigate([ `${ RoutesApp.AddRequestProcedure16 }` ]);
                } else {
                  this.router.navigate([ `${ RoutesApp.Dashboard }` ]);
                }
              } else {
                this.alert.errorAlert('Usuario no registrado por el flujo B2C', 4000);
                setTimeout(() => this.cleanAllAndLogout(), 4000);
              }
            });
          }, error: () => {
            this.alert.errorAlert('Usuario no registrado por el flujo B2C', 4000);
            setTimeout(() => this.cleanAllAndLogout(), 4000);
          }
        });
      }
    )

  }

  public loginventanilla() {
    const ventanilla = JSON.parse(localStorage.getItem('datosventanilla'));
    if (ventanilla) {
      this.userService.getRoleByIdUser(ventanilla.oid).subscribe(role => {
        if (role.data != null) {
          const currentUser = {
            access_token: ventanilla.flujo.idToken,
            sub: ventanilla.flujo.account.idTokenClaims.sub,
            refresh_token: ventanilla.flujo.account.idTokenClaims.auth_time + "",
            refresh_expires_in: ventanilla.flujo.account.idTokenClaims.exp,
            notBeforePolicy: ventanilla.flujo.account.idTokenClaims.iat ? ventanilla.flujo.account.idTokenClaims.iat : 0,
            scope: ventanilla.flujo.scopes[0] ? ventanilla.flujo.scopes[0] : '',
            id_token: ventanilla.flujo.idToken ? ventanilla.flujo.idToken : '',
            token_type: ventanilla.flujo.tokenType,
            session_state: ventanilla.flujo.state ? ventanilla.flujo.state : '',
            expires_in: ventanilla.flujo.account.idTokenClaims.exp,
            rol: this.getExactlyRole(role.data[0].value.toLowerCase()),
            sid: '',
            email_verified: role.data[0].email ? role.data[0].email : false,
            name: ventanilla.flujo.account.idTokenClaims.name,
            preferred_username: ventanilla.flujo.account.username,
            given_name: ventanilla.flujo.account.idTokenClaims.given_name + "",
            family_name: ventanilla.flujo.account.idTokenClaims.family_name + "",
            email: role.data[0].email,
            userId: ventanilla.flujo.account.idTokenClaims.sub,
          };
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.currentUserSubject.next(currentUser);
          if (currentUser.rol == Rol.CitizenOrApplicant) {
            this.router.navigate([ `${ RoutesApp.AddRequestProcedure16 }` ]);
          } else {
            this.router.navigate([ `${ RoutesApp.Dashboard }` ]);
          }
        } else {
          this.alert.errorAlert('Usuario no registrado por el flujo', 4000);
        }
      });
    }
  }

  private getExactlyRole(role): string {
    //roles en ventanilla contra los nuestros
    if (role == 'ciudadano') {
      return 'solicitante';
    }
    return role == 'funcionario' ? 'validador' : role;
  }

  cleanAll() {
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigateByUrl(RoutesApp.SignIn);
  }

  cleanAllAndLogout() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/"
      }).subscribe(resp => {
        //this.alert.infoAlert("¡Sesión cerrada exitosamente!", 4000);
        localStorage.clear();
        this.currentUserSubject.next(null);
      });
    } else {
      localStorage.clear();
      this.currentUserSubject.next(null);
    }
  }
}
