import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "@services";
import { SSOAuthResponse } from "@models";
import { Rol, RoutesApp } from "@enums";

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate {
  currentUser: SSOAuthResponse;

  constructor(private authServiceOld: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkIsLogged(route, url);
  }

  checkIsLogged(route: ActivatedRouteSnapshot, url: any): boolean {
    this.currentUser = this.authServiceOld.currentUserValue;
    if (this.currentUser) {
      if (this.currentUser.rol == Rol.CitizenOrApplicant) {
        this.router.navigateByUrl(`${ RoutesApp.AddRequestProcedure16 }`);
      } else {
        this.router.navigateByUrl(`${ RoutesApp.Dashboard }`);
      }
      return false;
    }
    localStorage.clear();
    return true;
  }

}
