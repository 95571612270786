// quill-rich-text configure inline styles
import Quill from "quill";

const fontSizeArr = [ '14px', '15px', '16px', '18px', '20px' ];

export const configQuill = {
  modules: {
    toolbar: [
      [ 'bold', 'italic', 'underline', 'strike' ],
      [ { align: [] } ],
      [ { 'size': [ 'Tamaño', ...fontSizeArr ] } ],
      [ { list: 'ordered' }, { list: 'bullet' } ],
      [ 'clean' ],
    ]
  }
};

export function setUpQuillConfig() {
  const AlignStyle = Quill.import("attributors/style/align");
  Quill.register(AlignStyle, true);

  const SizeStyle = Quill.import("attributors/style/size");
  SizeStyle.whitelist = fontSizeArr;
  Quill.register(SizeStyle, true);
  setDefaultMargins();
}

const setDefaultMargins = () => {
  const Block = Quill.import("blots/block");
  class Pblock extends Block {
    static create(value) {
      let node = super.create();
      node.setAttribute(
        "style",
        "line-height:normal"
        //"margin-top:0in;margin-right:0in;margin-bottom:5.0pt;margin-left:0in;line-height:107%;"
      );
      return node;
    }
  }
  Quill.register(Pblock, true);
};
