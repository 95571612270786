import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from "./core/core.module";

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

// antd configure
import en from '@angular/common/locales/en';
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n";

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalModule, MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import { MsalLocalGuard } from "./core/guards/msal-local.guard";
import { InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { msalConfigAzure } from "./authconfigAzure";
import { JwtInterceptor } from "./core/interceptors/jwt.interceptor";
import { DemoNgZorroAntdModule } from "./ng-antd.module";
import { MaterialModule } from "./material.module";
import { QuillModule } from "ngx-quill";
import { configQuill, setUpQuillConfig } from "./core/config/quill-text.config";

registerLocaleData(en);
// const antDesignIcons = AllIcons as {
//   [key: string]: IconDefinition;
// };
// const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactoryAzure(): IPublicClientApplication {
  return new PublicClientApplication(msalConfigAzure);
}


/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect, // InteractionType.Popup
  };
}
// quill configure
setUpQuillConfig();

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,

    AppRoutingModule,
    CoreModule,
    DemoNgZorroAntdModule,
    QuillModule.forRoot(configQuill),
    MaterialModule,
    NgxExtendedPdfViewerModule,
    MsalModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: Window, useValue: window },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactoryAzure
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalLocalGuard,
    MsalBroadcastService
  ],
  bootstrap: [ AppComponent, MsalRedirectComponent ]
})
export class AppModule {
}
