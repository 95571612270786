import { DocsRequirement, StatusRequest } from "@enums";

export const PROCEDURE16 = {
  T16_ID: 16,
  T16_NAME: 'Reconocimiento de personería jurídica de fundaciones corporaciones y/o asociaciones de utilidad común y/o sin ánimo de lucro',
  T16_DESCRIPTION: `Obtener la identificación legal para ejercer derechos y contraer obligaciones y
   de ser representadas judicial y extrajudicialmente, las asociaciones o corporaciones y
  fundaciones o instituciones de utilidad común que tengan su domicilio principal
  en la jurisdicción del ente territorial.`,
  FIRST_TIME: '2',
  ATTENTION_TIME: 30,
  STATES_AVAILABLE_TO_EDIT_OR_SEE: [
    StatusRequest.REQUEST_CREATED,
    StatusRequest.REQUEST_INCOMPLETE,
    StatusRequest.REQUEST_APPROVED_SIGNED,
    StatusRequest.REQUEST_DESIST_APPROVED_SIGNED,
    StatusRequest.REQUEST_APPROVED_RESOURCE,
    StatusRequest.REQUEST_WITH_PUBLICATION,
    StatusRequest.REQUEST_ENDED_APPROVED_SIGNED,
    StatusRequest.REQUEST_ANULA,
    StatusRequest.REQUEST_WITH_VISIT_ACT
  ],
  RICH_TEXT_DF: `<p style="line-height:normal"><br></p>`
};

export const FATHER_IDS = {
  ORG_ORIG: 25,
  TYPE_MEET: 13,
};

export const RequirementDocsT16 = [
  {
    id: DocsRequirement.CartaSolicManExpreFirmada,
    descripcion: 'Carta Solicitud Manifestación Expresa Firmada. Solicitud suscrita por el representante legal o la persona que se encuentre debidamente facultada para el efecto.',
    keyFileName: 'ManifestacionExpresaFirmada', //key in back for file versions
    selected: false,
    required: true
  },
  {
    id: DocsRequirement.CopiaActaEscrituraPub,
    descripcion: 'Copia del acta o escritura pública, según corresponda, en donde conste la voluntad expresa de constituir la entidad, la elección o designación de representante legal y demás dignatarios o miembros de los órganos de dirección y de fiscalización con su debida aceptación. y la aprobación impartida a los estatutos, en caso de nombramiento de revisor fiscal debe aportarse tarjeta profesional. Esta documentación será suscrita por el Presidente y el Secretario de la correspondiente sesión o por el fundador o fundadores.',
    keyFileName: 'ActaEscrituraPublica', //key in back for file versions
    selected: false,
    required: true
  },
  {
    id: DocsRequirement.DocumentoIdentidad,
    descripcion: 'Documento de identidad (Representante Legal)',
    keyFileName: 'DocuentoIdentidad', //key in back for file versions
    selected: false,
    required: true
  },
  {
    id: DocsRequirement.EstatutosEsalPresident,
    descripcion: 'Estatutos de la Esal suscritos por el Presidente y el Secretario de la reunión en que fueron aprobados o por el fundador o fundadores, observando los requisitos señalados en la normativa legal vigente.',
    keyFileName: 'EsalSuscrito', //key in back for file versions
    selected: false,
    required: true
  },
  {
    id: DocsRequirement.EstudioFactibilidad,
    descripcion: 'Estudio de factibilidad',
    keyFileName: 'EstudioFactibilidad', //key in back for file versions
    selected: false,
    required: true
  },
  {
    id: DocsRequirement.CertificacionAportIniciales,
    descripcion: 'Certificación y consignación de aportes iniciales, Documento que acredite la efectividad y seriedad de los aportes de acuerdo con su origen.',
    keyFileName: 'AportesIniciales', //key in back for file versions
    selected: false,
    required: true
  },
  {
    id: DocsRequirement.PoderEspecialDebidamentConf,
    descripcion: 'Poder especial debidamente conferido y aceptado junto con la tarjeta profesional de abogado.',
    keyFileName: 'TrajetaProfesionalAbogado', //key in back for file versions
    selected: false
  },
  {
    id: DocsRequirement.OtroDocumento,
    descripcion: 'Otro documento',
    keyFileName: 'OtroDocumento',
    selected: false,
    required: false
  },
];


//tmp
export function CUSTOM_NAME(name: string): string {
  return name.slice(0, 20).trim();
}

export const QuillEditorStyle = {
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  fontSize: '14px',
};

export const QuillEditorDisabledStyle = {
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  fontSize: '14px',
  backgroundColor: '#e9ecef'
};

export const MonthNames = [ '', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
