export enum DocsRequirement {
  CartaSolicManExpreFirmada = 12,
  CopiaActaEscrituraPub = 13,
  DocumentoIdentidad = 14,
  EstatutosEsalPresident = 15,
  EstudioFactibilidad = 16,
  CertificacionAportIniciales = 17,
  PoderEspecialDebidamentConf = 18,
  OtroDocumento = 19,
  DocumentoAsocRevision = 20,
  ActaVisitaRevision = 21,
  ComprobantePublicacion = 22,
  ComprobantePagoGaceta = 23,
  RecursoReposicion = 25
}
