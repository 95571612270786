import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AccesibilityComponent } from './components/accesibility/accesibility.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouterModule } from "@angular/router";
import { SigninComponent } from './components/signin/signin.component';
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../material.module";
import { NotFoundComponent } from './components/not-found/not-found.component';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    AccesibilityComponent,
    BreadcrumbComponent,
    SigninComponent,
    NotFoundComponent
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    AccesibilityComponent,
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule
  ]
})
export class CoreModule { }
