import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TramiteSharedService {

  constructor(private http: HttpClient) {
  }


   /** Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
   public getInfoUserByIdCodeVentanilla(idCode: any): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v2/Persona/GetInfoUserById/${ idCode }`);
  }

  /**
   * Obtiene la info de la tabla user de la base de datos de security
   * @param oidToken_usuario Id dado por el b2c
   */
  public getUser(oidToken_usuario: string): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SECURITY_URL }/v2/Security/GetUsers?type=codigo&search=${ oidToken_usuario }`);
  }

}
