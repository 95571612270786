import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { Breadcrumb } from "@models";
import { BreadcrumbService } from "../../services/breadcrumb.service";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: [ './breadcrumb.component.scss' ]
})
export class BreadcrumbComponent implements OnInit {

  // @ts-ignore
  breadcrumbs$: Observable<Breadcrumb[]>;
  lastSection: any;
  mapRoutes: any;

  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
  }

  ngOnInit() {
    this.breadcrumbService.getView$.subscribe(last => this.lastSection = last);
    this.breadcrumbs$.subscribe(r => {
      try {
        const [ label ] = r.filter(this.getLast);
        this.mapRoutes = label?.label;
      } catch (e) {
        console.error(e);
        throw e;
      }
    })
  }

  isInboxPath(b: any): boolean {
    return b?.url === '';
  }

  getLast = (element: any, index: number, array: any) => {
    if (index === array.length - 1) {
      return element;
    }
  }

}
