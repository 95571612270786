import { Component, Inject, OnInit } from '@angular/core';
import { SSOAuthResponse } from "@models";
import { Rol, RoutesApp } from "@enums";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import {AuthService, PopupService, RequestService} from "@services";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter } from "rxjs/operators";
import {InteractionStatus, PublicClientApplication} from "@azure/msal-browser";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {

  local;
  opacity;
  currentUser!: SSOAuthResponse;
  routes = RoutesApp;
  amountOfNotification = 5;
  public isLogueado = false;
  currentRol = Rol

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(private router: Router,
              @Inject(Window) private _window: Window,
              // private store: Store<GlobalState>,
              private authServiceOLD: AuthService, private requestService: RequestService,
              private authService: MsalService, private broadcastService: MsalBroadcastService,
              private  popUpService : PopupService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authServiceOLD.currentUserValue;
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
    this.authServiceOLD.currentUser.subscribe((res) => this.currentUser = res)
  }

  setLogout = (): void => {
    if (this.authService.instance.getAllAccounts().length > 0) {
      localStorage.clear();
      const logoutRedirect = `${ this._window.location.origin }/sign-in`;
      this.router.navigate([ 'sign-in' ]);
      let msalInstance: PublicClientApplication = this.authService.instance as PublicClientApplication;
      msalInstance['browserStorage'].clear();
      msalInstance['nativeInternalStorage'].clear();
      this.authService.logoutRedirect(
        { postLogoutRedirectUri: logoutRedirect}
      ).subscribe( resp => {});
    } else {
      localStorage.clear();
      this.authServiceOLD.currentUserSubject.next(null);
      setTimeout(() => {
        this.router.navigateByUrl(RoutesApp.SignIn);
      }, 1000)
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
