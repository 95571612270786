import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  setAddress = new BehaviorSubject(null);
  getAddress$ = this.setAddress.asObservable();

  constructor(private http: HttpClient) {
  }

  getAddress(address: string): Observable<any> {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Direccion/GetDireccion/${ address }`);
  }

  getZones() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/SubRed/GetSubRed`);
  }

  getLocalities() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Localidad/GetAllLocalidad`);
  }

  getUpzList() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Upz/GetUpz`);
  }

  getUpzByLocationId(localId) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Upz/GetUpzByIdLocalidad/${ localId }`);
  }

  getNeighborhoods() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrios`);
  }

  getBarriosByUpz(idUpz) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrios`).pipe(
      map(resp => resp?.data.filter(barrio => barrio.id_upz == idUpz))
    );
  }
}
