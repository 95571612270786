import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Constants } from "@models";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor(private http: HttpClient) {
  }

  getConstantsByFatherId(dadId: number): Observable<Constants[]> {
    return this.http.get<Constants[]>(`${ environment.T16_REQUEST_API_URL }/Constant/getConstantByIdFather/${ dadId }`);
  }

  /**
   *
   * @param idRequest
   */
  GetRequestReplacementMotive(idRequest): Observable<any> {
    return this.http.get<any>(`${ environment.T16_VALIDATION_API_URL }/Request/GetRequestReplacementMotive/${ idRequest }`);
  }
}
