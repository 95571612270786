import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RepresentativeService {
  representativeSource = new BehaviorSubject(null);
  representativeTarget$ = this.representativeSource.asObservable();
}
